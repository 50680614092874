import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import ContactUsBanner from '../images/contact_us.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const ContactPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/contact" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Contact" description='eWise - Contact Us'/>
     <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24 overflow-hidden">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative pb-3">Connect <strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90">now!</strong> </h1>
                                <div className="text">
                                    <p className="mb-3">If you are looking for the best services in technology; your search will inevitably end with us.</p>
                                    <p className="mb-3">Find the best experts, best solutions all under one roof at eWise! We customize our solutions to help you grow your business.</p>
                                    <p>Contact us now to know more about how we can help you <a title="sales@ewisesolutions.com" href="mailto:sales@ewisesolutions.com">sales@ewisesolutions.com</a> or call us at <a title="(+91) 8128531758" href="tel:(+91) 8128531758">(+91) 8128531758</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4">
                        <div className="inner relative flex justify-center">
                            <img className="w-full sm:w-auto max-w-400 md:max-w-500 lg:max-w-610 m-auto lazyload" loading="lazy" data-src={ContactUsBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
    <section className="relative pb-16 sm:pb-20 lg:pb-28">
      <div className="container">
          <div className="-mx-4">

            <div className="flex flex-wrap">
                <div className="map w-full md:w-1/2 order-2 md:order-1 mx-4 md:mx-0">
                    <div className="map_inner h-auto md:h-full relative pb-m-100 md:pb-0">
                        <iframe title='eWise Location' className="w-full h-full whitespace-nowrap  absolute left-0 top-0 md:relative " src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.7016731657395!2d72.51397091535468!3d23.071396320290216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9cb3b824590b%3A0x6016726bf3253f65!2sShukan%20Mall!5e0!3m2!1sen!2sin!4v1641026683290!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
                <div className="mx-4 md:mx-0 order-1 md:order-2 contact_us_box w-full md:w-1/2 py-8 sm:py-10 md:py-14 lg:py-16 xl:py-20 2xl:py-24 px-6 sm:px-8 md:px-10 lg:px-12  xl:px-12 2xl:px-16 bg-black">
                    <div className="text_content">
                        <h4 className="pb-4 md:pb-4 lg:pb-6 text-26 md:text-3xl lg:text-32 xl:text-35 2xl:text-40 text-white"><strong>Contact Us</strong><span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                        <p className="pb-8 text-white">Our journey started way back in the year 2014 has taken us to new heights as we have grown to a fully efficient business solutions’ provider; starting with only 6 team members and now with almost 12 core decision makers and team members.</p>
                    </div>
                    <ul>
                        <h5 className='text-white font-bold mb-5'>Dubai</h5>
                        <li className="leading-none mb-6">
                            <a className="relative pl-8 text-white hover:text-pink transition duration-150 ease-in-out text-sm 2xl:text-lg font-medium" href="tel:(+91) 8128531758" title="(+91) 8128531758">(+971) 050 7185309
                                <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-white hover:fill-pink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 512 512"
                                 >
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                        <path className="st0"
                                                    d="M916,5105c-81-21-164-67-249-140c-94-81-431-424-486-495c-223-285-227-728-11-1300c263-699,756-1407,1380-1982
                                                                                                                C2410,395,3562-118,4155,26c88,21,234,89,301,140c68,51,520,515,562,576c78,113,108,264,78,392c-34,143-45,156-480,592
                                                                                                                c-439,439-453,450-598,480c-115,24-232,3-345-62c-26-16-150-129-275-253l-227-224l-73,38c-179,93-311,182-508,344
                                                                                                                c-202,166-484,457-640,661c-113,148-279,421-280,458c0,7,78,89,173,182c200,195,279,286,324,372c56,109,67,247,28,361
                                                                                                                c-39,116-77,160-459,543c-378,378-436,428-544,465C1117,5118,993,5124,916,5105z M1148,4824c28-16,188-167,403-383
                                                                                                                c389-390,404-408,403-511c0-91-24-123-285-384c-132-131-247-252-255-269c-12-21-15-50-12-99c3-60,11-83,72-204
                                                                                                                c138-274,320-515,621-823c299-306,597-534,904-692c109-56,130-64,180-63c34,0,70,7,91,18c19,10,143,126,275,257
                                                                                                                c170,169,253,244,285,258c60,27,138,27,193,0c60-30,767-736,798-798c17-33,24-63,24-106c0-103-21-131-305-416
                                                                                                                c-281-282-307-301-457-335c-145-32-388-6-634,67c-375,111-879,377-1294,683C1356,1612,670,2511,385,3342c-136,395-151,678-47,888
                                                                                                                c32,67,62,101,270,312c128,130,251,248,272,263C970,4867,1062,4874,1148,4824z" />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li className="leading-none mb-6">
                                <a className="relative pl-8 text-white hover:text-pink transition duration-150 ease-in-out text-sm 2xl:text-lg font-medium" href="mailto:sales@ewisesolutions.com" title="sales@ewisesolutions.com">sales@ewisesolutions.com
                                    <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-white hover:fill-pink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 512 512"
                                            >
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                            <path className="st0"
                                                        d="M340,4469c-160-31-299-173-330-339c-14-72-14-3067,0-3141c32-169,170-307,340-339c73-14,4347-14,4420,0
                                            c170,32,308,170,340,339c14,74,14,3068,0,3142c-32,169-170,307-340,339C4703,4483,405,4482,340,4469z M4714,4261
                                            c19-7-210-221-1517-1409c-526-479-507-463-552-483c-51-24-119-24-171,0c-38,17-508,440-1628,1465c-378,346-455,421-440,427
                                            C431,4271,4689,4271,4714,4261z M1066,3344c462-421,840-769,842-773c1-5-91-91-205-192c-288-255-641-580-1086-998
                                            c-208-196-381-357-386-359s-11,7-15,20c-8,30-8,2996,0,3037c4,17,8,31,9,31C227,4110,605,3766,1066,3344z M4909,2565
                                            c0-825-2-1510-5-1523c-4-13-10-22-15-20s-178,163-386,359c-445,418-798,743-1086,998c-114,101-206,187-205,192c2,4,381,353,843,775
                                            c763,697,840,764,846,743C4905,4076,4908,3390,4909,2565z M2197,2314c171-153,278-196,426-173c98,15,170,57,301,174
                                            c102,92,120,104,135,93c120-94,675-599,1249-1137c380-356,430-407,410-413c-13-3-984-6-2158-6s-2145,3-2158,6c-20,6,31,57,410,413
                                            c238,223,516,482,618,575c192,177,639,574,645,574C2077,2420,2132,2373,2197,2314z" />
                                        </g>
                                    </svg>
                                </a>
                        </li>
                        <li className="mb-4">
                            <p className="relative pl-8 text-white text-sm 2xl:text-lg font-light leading-26 ">P2-ELOB Office NO. E2-110G-17 Al Hamriyah Free Zone - Sharjah Dubai, United Arab Emirates P.O Box: 0 HFZA, SHJ, UAE
                                <svg className="svg_icons absolute max-w-21 max-h-21 left-0 top-0  fill-white"
                                        version="1.0"
                                        viewBox="0 0 512.000000 512.000000"
                                        preserveAspectRatio="xMidYMid meet">

                                    <g className=""
                                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        stroke="none">
                                        <path d="M2425 5114 c-459 -46 -827 -216 -1135 -524 -542 -542 -682 -1357
                                -351 -2045 69 -144 1497 -2454 1547 -2502 62 -60 160 -53 215 17 40 52 1415
                                2351 1465 2450 82 164 136 328 171 520 21 116 23 425 4 545 -63 398 -234 738
                                -511 1015 -275 275 -624 452 -1009 510 -80 12 -330 21 -396 14z m345 -309
                                c311 -44 625 -201 849 -426 211 -211 348 -467 412 -769 33 -152 33 -430 1
                                -580 -30 -139 -74 -264 -132 -380 -27 -52 -334 -571 -683 -1153 l-634 -1059
                                -661 1065 c-363 586 -681 1105 -705 1153 -98 196 -145 379 -154 604 -27 691
                                414 1304 1077 1499 199 59 420 75 630 46z" />
                                        <path d="M2397 4205 c-338 -64 -617 -324 -708 -660 -31 -114 -32 -335 -1 -450
                                68 -251 244 -466 475 -580 142 -70 224 -88 397 -88 173 -1 253 17 397 88 232
                                114 407 328 475 580 31 115 30 336 -1 450 -85 312 -338 565 -646 645 -106 28
                                -283 35 -388 15z m285 -296 c96 -20 208 -80 284 -152 77 -72 124 -143 162
                                -245 22 -58 26 -84 26 -187 1 -128 -9 -175 -62 -280 -72 -147 -231 -271 -393
                                -310 -373 -88 -738 200 -739 582 0 98 15 163 61 262 72 155 233 286 403 327
                                65 16 190 17 258 3z" />
                                    </g>
                                </svg>
                            </p>
                        </li>
                </ul>
                <ul>
                        <h5 className='text-white font-bold mb-5'>Kenya</h5>
                        <li className="leading-none mb-6">
                            <a className="relative pl-8 text-white hover:text-pink transition duration-150 ease-in-out text-sm 2xl:text-lg font-medium" href="tel:(+91) 8128531758" title="(+91) 8128531758">(+254) 754333748 
                                <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-white hover:fill-pink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 512 512"
                                 >
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                        <path className="st0"
                                                    d="M916,5105c-81-21-164-67-249-140c-94-81-431-424-486-495c-223-285-227-728-11-1300c263-699,756-1407,1380-1982
                                                                                                                C2410,395,3562-118,4155,26c88,21,234,89,301,140c68,51,520,515,562,576c78,113,108,264,78,392c-34,143-45,156-480,592
                                                                                                                c-439,439-453,450-598,480c-115,24-232,3-345-62c-26-16-150-129-275-253l-227-224l-73,38c-179,93-311,182-508,344
                                                                                                                c-202,166-484,457-640,661c-113,148-279,421-280,458c0,7,78,89,173,182c200,195,279,286,324,372c56,109,67,247,28,361
                                                                                                                c-39,116-77,160-459,543c-378,378-436,428-544,465C1117,5118,993,5124,916,5105z M1148,4824c28-16,188-167,403-383
                                                                                                                c389-390,404-408,403-511c0-91-24-123-285-384c-132-131-247-252-255-269c-12-21-15-50-12-99c3-60,11-83,72-204
                                                                                                                c138-274,320-515,621-823c299-306,597-534,904-692c109-56,130-64,180-63c34,0,70,7,91,18c19,10,143,126,275,257
                                                                                                                c170,169,253,244,285,258c60,27,138,27,193,0c60-30,767-736,798-798c17-33,24-63,24-106c0-103-21-131-305-416
                                                                                                                c-281-282-307-301-457-335c-145-32-388-6-634,67c-375,111-879,377-1294,683C1356,1612,670,2511,385,3342c-136,395-151,678-47,888
                                                                                                                c32,67,62,101,270,312c128,130,251,248,272,263C970,4867,1062,4874,1148,4824z" />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li className="leading-none mb-6">
                                <a className="relative pl-8 text-white hover:text-pink transition duration-150 ease-in-out text-sm 2xl:text-lg font-medium" href="mailto:sales@ewisesolutions.com" title="sales@ewisesolutions.com">indokenyapetro@gmail.com
                                    <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-white hover:fill-pink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 512 512"
                                            >
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                            <path className="st0"
                                                        d="M340,4469c-160-31-299-173-330-339c-14-72-14-3067,0-3141c32-169,170-307,340-339c73-14,4347-14,4420,0
                                            c170,32,308,170,340,339c14,74,14,3068,0,3142c-32,169-170,307-340,339C4703,4483,405,4482,340,4469z M4714,4261
                                            c19-7-210-221-1517-1409c-526-479-507-463-552-483c-51-24-119-24-171,0c-38,17-508,440-1628,1465c-378,346-455,421-440,427
                                            C431,4271,4689,4271,4714,4261z M1066,3344c462-421,840-769,842-773c1-5-91-91-205-192c-288-255-641-580-1086-998
                                            c-208-196-381-357-386-359s-11,7-15,20c-8,30-8,2996,0,3037c4,17,8,31,9,31C227,4110,605,3766,1066,3344z M4909,2565
                                            c0-825-2-1510-5-1523c-4-13-10-22-15-20s-178,163-386,359c-445,418-798,743-1086,998c-114,101-206,187-205,192c2,4,381,353,843,775
                                            c763,697,840,764,846,743C4905,4076,4908,3390,4909,2565z M2197,2314c171-153,278-196,426-173c98,15,170,57,301,174
                                            c102,92,120,104,135,93c120-94,675-599,1249-1137c380-356,430-407,410-413c-13-3-984-6-2158-6s-2145,3-2158,6c-20,6,31,57,410,413
                                            c238,223,516,482,618,575c192,177,639,574,645,574C2077,2420,2132,2373,2197,2314z" />
                                        </g>
                                    </svg>
                                </a>
                        </li>
                        <li className="mb-4">
                            <p className="relative pl-8 text-white text-sm 2xl:text-lg font-light leading-26 ">P.O.Box 41095-80100,
 Mombasa-Kenya
Plot LR No. KAWALA "A" Kadzonzo
-/399, KoKotoni, Kilifi country
                                <svg className="svg_icons absolute max-w-21 max-h-21 left-0 top-0  fill-white"
                                        version="1.0"
                                        viewBox="0 0 512.000000 512.000000"
                                        preserveAspectRatio="xMidYMid meet">

                                    <g className=""
                                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        stroke="none">
                                        <path d="M2425 5114 c-459 -46 -827 -216 -1135 -524 -542 -542 -682 -1357
                                -351 -2045 69 -144 1497 -2454 1547 -2502 62 -60 160 -53 215 17 40 52 1415
                                2351 1465 2450 82 164 136 328 171 520 21 116 23 425 4 545 -63 398 -234 738
                                -511 1015 -275 275 -624 452 -1009 510 -80 12 -330 21 -396 14z m345 -309
                                c311 -44 625 -201 849 -426 211 -211 348 -467 412 -769 33 -152 33 -430 1
                                -580 -30 -139 -74 -264 -132 -380 -27 -52 -334 -571 -683 -1153 l-634 -1059
                                -661 1065 c-363 586 -681 1105 -705 1153 -98 196 -145 379 -154 604 -27 691
                                414 1304 1077 1499 199 59 420 75 630 46z" />
                                        <path d="M2397 4205 c-338 -64 -617 -324 -708 -660 -31 -114 -32 -335 -1 -450
                                68 -251 244 -466 475 -580 142 -70 224 -88 397 -88 173 -1 253 17 397 88 232
                                114 407 328 475 580 31 115 30 336 -1 450 -85 312 -338 565 -646 645 -106 28
                                -283 35 -388 15z m285 -296 c96 -20 208 -80 284 -152 77 -72 124 -143 162
                                -245 22 -58 26 -84 26 -187 1 -128 -9 -175 -62 -280 -72 -147 -231 -271 -393
                                -310 -373 -88 -738 200 -739 582 0 98 15 163 61 262 72 155 233 286 403 327
                                65 16 190 17 258 3z" />
                                    </g>
                                </svg>
                            </p>
                        </li>
                </ul>
                <ul className='mt-8'>
                <h5 className='text-white font-bold mb-5'>India</h5>
                        <li className="leading-none mb-6">
                            <a className="relative pl-8 text-white hover:text-pink transition duration-150 ease-in-out text-sm 2xl:text-lg font-medium" href="tel:(+91) 8128531758" title="(+91) 8128531758">(+91) 8128531758
                                <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-white hover:fill-pink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 512 512"
                                 >
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                        <path className="st0"
                                                    d="M916,5105c-81-21-164-67-249-140c-94-81-431-424-486-495c-223-285-227-728-11-1300c263-699,756-1407,1380-1982
                                                                                                                C2410,395,3562-118,4155,26c88,21,234,89,301,140c68,51,520,515,562,576c78,113,108,264,78,392c-34,143-45,156-480,592
                                                                                                                c-439,439-453,450-598,480c-115,24-232,3-345-62c-26-16-150-129-275-253l-227-224l-73,38c-179,93-311,182-508,344
                                                                                                                c-202,166-484,457-640,661c-113,148-279,421-280,458c0,7,78,89,173,182c200,195,279,286,324,372c56,109,67,247,28,361
                                                                                                                c-39,116-77,160-459,543c-378,378-436,428-544,465C1117,5118,993,5124,916,5105z M1148,4824c28-16,188-167,403-383
                                                                                                                c389-390,404-408,403-511c0-91-24-123-285-384c-132-131-247-252-255-269c-12-21-15-50-12-99c3-60,11-83,72-204
                                                                                                                c138-274,320-515,621-823c299-306,597-534,904-692c109-56,130-64,180-63c34,0,70,7,91,18c19,10,143,126,275,257
                                                                                                                c170,169,253,244,285,258c60,27,138,27,193,0c60-30,767-736,798-798c17-33,24-63,24-106c0-103-21-131-305-416
                                                                                                                c-281-282-307-301-457-335c-145-32-388-6-634,67c-375,111-879,377-1294,683C1356,1612,670,2511,385,3342c-136,395-151,678-47,888
                                                                                                                c32,67,62,101,270,312c128,130,251,248,272,263C970,4867,1062,4874,1148,4824z" />
                                    </g>
                                </svg>
                            </a>
                        </li>
                        <li className="leading-none mb-6">
                                <a className="relative pl-8 text-white hover:text-pink transition duration-150 ease-in-out text-sm 2xl:text-lg font-medium" href="mailto:sales@ewisesolutions.com" title="sales@ewisesolutions.com">sales@ewisesolutions.com
                                    <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-white hover:fill-pink"
                                            version="1.1"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 512 512"
                                            >
                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                            <path className="st0"
                                                        d="M340,4469c-160-31-299-173-330-339c-14-72-14-3067,0-3141c32-169,170-307,340-339c73-14,4347-14,4420,0
                                            c170,32,308,170,340,339c14,74,14,3068,0,3142c-32,169-170,307-340,339C4703,4483,405,4482,340,4469z M4714,4261
                                            c19-7-210-221-1517-1409c-526-479-507-463-552-483c-51-24-119-24-171,0c-38,17-508,440-1628,1465c-378,346-455,421-440,427
                                            C431,4271,4689,4271,4714,4261z M1066,3344c462-421,840-769,842-773c1-5-91-91-205-192c-288-255-641-580-1086-998
                                            c-208-196-381-357-386-359s-11,7-15,20c-8,30-8,2996,0,3037c4,17,8,31,9,31C227,4110,605,3766,1066,3344z M4909,2565
                                            c0-825-2-1510-5-1523c-4-13-10-22-15-20s-178,163-386,359c-445,418-798,743-1086,998c-114,101-206,187-205,192c2,4,381,353,843,775
                                            c763,697,840,764,846,743C4905,4076,4908,3390,4909,2565z M2197,2314c171-153,278-196,426-173c98,15,170,57,301,174
                                            c102,92,120,104,135,93c120-94,675-599,1249-1137c380-356,430-407,410-413c-13-3-984-6-2158-6s-2145,3-2158,6c-20,6,31,57,410,413
                                            c238,223,516,482,618,575c192,177,639,574,645,574C2077,2420,2132,2373,2197,2314z" />
                                        </g>
                                    </svg>
                                </a>
                        </li>
                        <li className="mb-4">
                            <p className="relative pl-8 text-white text-sm 2xl:text-lg font-light leading-26 ">603, City Centre, Opp.  Shukan Mall, Science City Road,Ahmedabad-380060  
                                <svg className="svg_icons absolute max-w-21 max-h-21 left-0 top-0  fill-white"
                                        version="1.0"
                                        viewBox="0 0 512.000000 512.000000"
                                        preserveAspectRatio="xMidYMid meet">

                                    <g className=""
                                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        stroke="none">
                                        <path d="M2425 5114 c-459 -46 -827 -216 -1135 -524 -542 -542 -682 -1357
                                -351 -2045 69 -144 1497 -2454 1547 -2502 62 -60 160 -53 215 17 40 52 1415
                                2351 1465 2450 82 164 136 328 171 520 21 116 23 425 4 545 -63 398 -234 738
                                -511 1015 -275 275 -624 452 -1009 510 -80 12 -330 21 -396 14z m345 -309
                                c311 -44 625 -201 849 -426 211 -211 348 -467 412 -769 33 -152 33 -430 1
                                -580 -30 -139 -74 -264 -132 -380 -27 -52 -334 -571 -683 -1153 l-634 -1059
                                -661 1065 c-363 586 -681 1105 -705 1153 -98 196 -145 379 -154 604 -27 691
                                414 1304 1077 1499 199 59 420 75 630 46z" />
                                        <path d="M2397 4205 c-338 -64 -617 -324 -708 -660 -31 -114 -32 -335 -1 -450
                                68 -251 244 -466 475 -580 142 -70 224 -88 397 -88 173 -1 253 17 397 88 232
                                114 407 328 475 580 31 115 30 336 -1 450 -85 312 -338 565 -646 645 -106 28
                                -283 35 -388 15z m285 -296 c96 -20 208 -80 284 -152 77 -72 124 -143 162
                                -245 22 -58 26 -84 26 -187 1 -128 -9 -175 -62 -280 -72 -147 -231 -271 -393
                                -310 -373 -88 -738 200 -739 582 0 98 15 163 61 262 72 155 233 286 403 327
                                65 16 190 17 258 3z" />
                                    </g>
                                </svg>
                            </p>
                        </li>
                </ul>
                </div>
                <div className=""></div>
            </div>
          </div>
      </div>
  </section>
    </Layout>
  )
}

export default ContactPage